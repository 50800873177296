<template>
  <template v-if="model['casino']">
    <h4 class="rollname">{{$t('front.gnb.casino')}}</h4>
    <div class="board" :class="className">
      <ul class="boardstr">
        <li class="boardstrlt">{{$t('front.board.type')}}</li>
        <li v-for="item in model['casino'].list" v-bind:key="item.codeName">
          <template v-if="$i18n.locale === 'ko'">{{item.codeName}}</template>
          <template v-if="$i18n.locale === 'en'">{{item.codeNameEN}}</template>
        </li>
        <li>{{$t('front.common.totals')}}</li>
      </ul>
      <ul class="boardstr">
        <li class="boardstrlt">{{$t('front.mypage.dividend')}}</li>
        <li v-for="item in model['casino'].list" v-bind:key="item.codeName">
          {{thousand(item.betWinAmt || 0)}}
        </li>
        <li>{{thousand(model['casino'].summary.betWinAmt || 0)}}</li>
      </ul>
      <ul class="boardstr">
        <li class="boardstrlt">{{$t('front.mypage.winlose')}}</li>
        <li v-for="item in model['casino'].list" v-bind:key="item.codeName">
          {{thousand(item.wlAmt || 0)}}
        </li>
        <li>{{thousand(model['casino'].summary.wlAmt || 0)}}</li>
      </ul>
      <ul class="boardstr">
        <li class="boardstrlt">{{$t('front.mypage.resultMoney')}}</li>
        <li v-for="item in model['casino'].list" v-bind:key="item.codeName">
          {{thousand(item.pointAmt || 0)}}
        </li>
        <li>{{thousand(model['casino'].summary.pointAmt || 0)}}</li>
      </ul>
    </div>
  </template>

  <template v-if="model['slot']">
    <h4 class="rollname">{{$t('front.gnb.slot')}}</h4>
    <div class="board" :class="className">
      <ul class="boardstr">
        <li class="boardstrlt">{{$t('front.board.type')}}</li>
        <li v-for="item in model['slot'].list" v-bind:key="item.codeName">
          <template v-if="$i18n.locale === 'ko'">{{item.codeName}}</template>
          <template v-if="$i18n.locale === 'en'">{{item.codeNameEN}}</template>
        </li>
        <li>{{$t('front.common.totals')}}</li>
      </ul>
      <ul class="boardstr">
        <li class="boardstrlt">{{$t('front.mypage.dividend')}}</li>
        <li v-for="item in model['slot'].list" v-bind:key="item.codeName">
          {{thousand(item.betWinAmt || 0)}}
        </li>
        <li>{{thousand(model['slot'].summary.betWinAmt || 0)}}</li>
      </ul>
      <ul class="boardstr">
        <li class="boardstrlt">{{$t('front.mypage.winlose')}}</li>
        <li v-for="item in model['slot'].list" v-bind:key="item.codeName">
          {{thousand(item.wlAmt || 0)}}
        </li>
        <li>{{thousand(model['slot'].summary.wlAmt || 0)}}</li>
      </ul>
      <ul class="boardstr">
        <li class="boardstrlt">{{$t('front.mypage.resultMoney')}}</li>
        <li v-for="item in model['slot'].list" v-bind:key="item.codeName">
          {{thousand(item.pointAmt || 0)}}
        </li>
        <li>{{thousand(model['slot'].summary.pointAmt || 0)}}</li>
      </ul>
    </div>
  </template>

  <template v-if="model['sport']">
    <h4 class="rollname">{{$t('front.gnb.sport')}}</h4>
    <div class="board" :class="className">
      <ul class="boardstr">
        <li class="boardstrlt">{{$t('front.board.type')}}</li>
        <li v-for="item in model['sport'].list" v-bind:key="item.codeName">
          <template v-if="$i18n.locale === 'ko'">{{item.codeName}}</template>
          <template v-if="$i18n.locale === 'en'">{{item.codeNameEN}}</template>
        </li>
        <li>{{$t('front.common.totals')}}</li>
      </ul>
      <ul class="boardstr">
        <li class="boardstrlt">{{$t('front.mypage.dividend')}}</li>
        <li v-for="item in model['sport'].list" v-bind:key="item.codeName">
          {{thousand(item.betWinAmt || 0)}}
        </li>
        <li>{{thousand(model['slot'].summary.betWinAmt || 0)}}</li>
      </ul>
      <ul class="boardstr">
        <li class="boardstrlt">{{$t('front.mypage.winlose')}}</li>
        <li v-for="item in model['sport'].list" v-bind:key="item.codeName">
          {{thousand(item.wlAmt || 0)}}
        </li>
        <li>{{thousand(model['slot'].summary.wlAmt || 0)}}</li>
      </ul>
      <ul class="boardstr">
        <li class="boardstrlt">{{$t('front.mypage.resultMoney')}}</li>
        <li v-for="item in model['sport'].list" v-bind:key="item.codeName">
          {{thousand(item.pointAmt || 0)}}
        </li>
        <li>{{thousand(model['sport'].summary.pointAmt || 0)}}</li>
      </ul>
    </div>
  </template>

  <template v-if="model['minigame']">
    <h4 class="rollname">{{$t('front.gnb.minigame')}}</h4>
    <div class="board" :class="className">
      <ul class="boardstr">
        <li class="boardstrlt">{{$t('front.board.type')}}</li>
        <li v-for="item in model['minigame'].list" v-bind:key="item.codeName">
          <template v-if="$i18n.locale === 'ko'">{{item.codeName}}</template>
          <template v-if="$i18n.locale === 'en'">{{item.codeNameEN}}</template>
        </li>
        <li>{{$t('front.common.totals')}}</li>
      </ul>
      <ul class="boardstr">
        <li class="boardstrlt">{{$t('front.mypage.dividend')}}</li>
        <li v-for="item in model['minigame'].list" v-bind:key="item.codeName">
          {{thousand(item.betWinAmt || 0)}}
        </li>
        <li>{{thousand(model['minigame'].summary.betWinAmt || 0)}}</li>
      </ul>
      <ul class="boardstr">
        <li class="boardstrlt">{{$t('front.mypage.winlose')}}</li>
        <li v-for="item in model['minigame'].list" v-bind:key="item.codeName">
          {{thousand(item.wlAmt || 0)}}
        </li>
        <li>{{thousand(model['minigame'].summary.wlAmt || 0)}}</li>
      </ul>
      <ul class="boardstr">
        <li class="boardstrlt">{{$t('front.mypage.resultMoney')}}</li>
        <li v-for="item in model['minigame'].list" v-bind:key="item.codeName">
          {{thousand(item.pointAmt || 0)}}
        </li>
        <li>{{thousand(model['minigame'].summary.pointAmt || 0)}}</li>
      </ul>
    </div>
  </template>

</template>

<script>
export default {
  name: 'retailDetail',
  props: {
    className: {
      type: String,
      default: ''
    },
    list: {
      type: Object,
      default: null,
      required: true
    }
  },
  data () {
    return {
      model: {},
      sumBetWinAmt: 0,
      sumWlAmt: 0,
      sumPointAmt: 0
    }
  },
  created () {
    this.calculate()
  },
  methods: {
    calculate () {
      if (this.list) {
        for (const category in this.list) {
          if (!this.model[category]) {
            this.model[category] = {
              summary: {
                pointAmt: 0,
                betWinAmt: 0,
                wlAmt: 0
              },
              list: []
            }
          }

          const summary = this.model[category].summary
          this.model[category].list = this.list[category]
          this.list[category].forEach(item => {
            summary.betWinAmt = summary.betWinAmt + item.betWinAmt
            summary.pointAmt = summary.pointAmt + item.pointAmt
            summary.wlAmt = summary.wlAmt + item.wlAmt
          })

          this.sumBetWinAmt += summary.betWinAmt
          this.sumWlAmt += summary.pointAmt
          this.sumPointAmt += summary.wlAmt
        }
      }
    }
  }
}
</script>

<style scoped>
.nonebtn {background: none !important;border: 0 !important;width: auto !important;}
.rollname {font-size: 16px;font-weight: bold;color: #4c4c4c;margin-bottom: 10px;}
.close {position: absolute;right: 15px;top: 15px;}
.board {box-shadow: 0 0px 6px 0 rgb(0 0 0 / 20%);border-radius: 10px;}
.boardstrin .rollname {margin-top: 30px;}
.board.even .boardstr {background: #f8f9fb;}
.board>.boardstr:first-child .boardstrlt{border-radius: 10px 0 0 0;}
</style>
<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
